<template>
  <!-- -->
  <div class="settingStyle" :style="'backgroundImage:url(' + require('@/assets/img/homeBJ.jpg') + ');'">
    <div class="outer" :style="'max-height:' + height + 'px;height:' + height + 'px;'">
      <img src="@/assets/img/home1.png" alt="" class="img1">
      <div class="cont">
        <div class="contTip"> 为培养群众防火意识，凝聚全社会之力防范森林草原火灾发生，确保我市森林草原资源安全、社会和谐稳定，石家庄市林业局开展2024年森林草原防火网络知识竞赛，参与者将有机会获得红包奖励！
          点击下方“立即答题”，进入答题环节，
          每个用户每日有3次答题机会，参与者就有机会赢取红包奖励。
          还等什么？快点击参与吧！
        </div>
        <img src="@/assets/img/home3.png" alt="" class="img3" @click="partake">
        <div @click="guize" class="guize">活动规则</div>
      </div>
      <img src="@/assets/img/home2.png" alt="" class="img2">

    </div>
    <!-- 答题弹框 -->
    <van-popup v-model="show" round position="bottom">
      <div class="popup_cont">
        <div class="popup_tip_one">请输入您的真实手机号</div>
        <div class="popup_tip_two">答题全部正确者“有机会”获得{{ info.answer_money }}元奖励</div>
        <input type="number" placeholder="请输入" v-model="phone" class="phone">
        <div class="popup_tip_three">每位用户每天有{{ info.answer_chance_count }}次答题机会，点击开始答题后请勿刷新页面，否则会扣除一次机会。</div>
        <div class="popup_three">
          <div class="popup_three_one" @click="partakeClose">返回</div>
          <div class="popup_three_two" @click="answer" v-throttle>开始答题</div>
        </div>
      </div>
    </van-popup>

    <!-- 我的浮窗 -->
    <div class="mine_box" @click="tomine">
      <img src="@/assets/img/mineimg.png" alt="">
      <div>我的</div>
    </div>

    <!-- 弹窗规则 -->
    <van-popup v-model="guizeshow" style="width: 85%;border-radius: 10px;" @close="bose">
      <div class="guize_box">
        <div class="guize_title">活动规则</div>
        <div class="guize_neirong">
          <div class="fuwen" v-html="info.huodong_info"></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：首页
 * @author: 刘嘉鑫 
 * @version  V1.0 
 * @datecreate: 2023-03-02 15:13
 */
export default {
  data() {
    return {
      height: "", // 页面高度
      show: false,
      phone: "", // 手机号
      info: "",
      guizeshow: false,
    };
  },
  computed: {
    disposefn() {
      return this.$store.state.dispose;
    },
  },
  watch: {
    height(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.height = val
        this.timer = true
        let that = this
        setTimeout(function () {
          that.timer = false
        }, 400)
      }
    },
    disposefn: {
      handler: function (val, oldval) {
        this.info = val
      },
      deep: true
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.height = document.documentElement.clientHeight
      }, 100);
    })
    window.onresize = () => {
      return (() => {
        this.height = document.documentElement.clientHeight
      })()
    }
    if (this.$store.state.isShow == true) {
      this.guizeshow = true
    }
    this.info = this.$store.state.dispose
  },
  deforeDestroy() {

  },
  methods: {
    /**
     * 规则弹窗关闭触发
     * 刘义博
     * 2024-4-25
     */
    bose() {
      this.$store.state.isShow = false
    },
    /**
     * 规则弹窗弹出
     * 刘义博
     * 2024-4-25
     */
    guize() {
      this.guizeshow = true
    },
    /**
     * 去我的页面
     * 刘义博
     * 2023-4-16
     */
    tomine() {
      if (!localStorage.getItem('token')) {
        this.$toast({
          message: '请先点击参与活动，再进行操作',
          duration: 1500,
        });
        return
      }
      this.$router.push({ path: "/mine" });
    },
    /**
     * 点击参与
     * 刘嘉鑫
     * 2023-3-2
     */
    partake() {
      this.show = true
      this.$parent.$refs.audio.play();
    },
    partakeClose() {
      this.show = false
    },

    /**
     * 开始答题
     * 刘嘉鑫
     * 2023-3-2
     */
    answer() {
      if (this.phone == "") {
        this.$toast({
          message: '请输入手机号',
          duration: 1500
        })
        return
      } else if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast({
          message: "手机号不正确",
          duration: 1500,
        });
        return;
      } else {
        this.$request({
          url: "/Index/login",
          data: {
            mobile: this.phone,
          },
        }).then((res) => {
          if (res.data.answer_chance_count > 0) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("phone", this.phone);
            this.$router.push({
              path: "/answer",
            });
          } else {
            this.$toast({
              message: "您的答题次数已用完！",
              duration: 2000,
            });
            this.phone = ""
          }
        }).catch((error) => {
          if (error.code == '-999') {
            console.log(error, '不在时间范围内！');
            localStorage.setItem("token", error.data.token);
            localStorage.setItem("phone", this.phone);
            this.$toast({
              message: error.msg,
              duration: 2000,
            });
            setTimeout(() => {
              this.$router.push({ path: "/mine" });
            }, 2000)
            return
          }

        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 54px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.guize {
  font-size: 30px;
  color: #888888;
}

.guize_box {
  padding: 40px;
  font-size: 35px;

  .guize_title {
    text-align: center;
    font-weight: bold;
  }

  .guize_neirong {
    .fuwen {
      font-size: 30px;
      line-height: 48px;
    }
  }
}

.img1 {
  width: 100%;
  height: 189px;
  margin-top: 110px;
}

.cont {
  border: 2px solid #05B252;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.8) 100%);
  border-radius: 12px;
  padding: 40px 46px 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 6px;
}

.contTip {
  font-size: 30px;
  font-weight: 400;
  color: #222222;
  line-height: 60px;
  margin-bottom: 40px;
}

.img3 {
  width: 306px;
  height: 142px;
}

.img2 {
  width: 100%;
  height: 37px;
  margin-bottom: 110px;
}

.popup_cont {
  padding: 60px 54px 78px;

  .popup_tip_one {
    font-size: 36px;
    font-weight: bold;
    color: #222222;
    margin-bottom: 20px;
    text-align: center;
  }

  .popup_tip_two {
    font-size: 28px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    margin-bottom: 80px;
  }

  .phone {
    width: 100%;
    background: #EFEFEF;
    border-radius: 60px;
    padding: 34px 0;
    text-align: center;
    margin-bottom: 26px;
    border: none !important;
    font-size: 34px;
    font-weight: bold;
    color: #222222;
  }

  .popup_tip_three {
    font-size: 26px;
    font-weight: 400;
    color: #666666;
    text-align: center;
    margin-bottom: 80px;
  }

  .popup_three {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    >div {
      border-radius: 60px;
      width: 47%;
      text-align: center;
      font-size: 34px;
      font-weight: bold;
      padding: 32px 0;
    }

    .popup_three_one {
      background: #EFEFEF;
      color: #222222;
    }

    .popup_three_two {
      background: #05B252;
      color: #FFFFFF;
    }

  }
}

.mine_box {
  position: absolute;
  top: 30px;
  right: 0;
  width: 140px;
  height: 60px;
  background: #05B252;
  border-radius: 30px 0px 0px 30px;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 29px;
    height: 28px;
    margin-right: 8px;
  }

  div {
    font-weight: bold;
    font-size: 26px;
    color: #FFFFFF;
  }
}
</style>
